import React from "react"
import imgPortadaGuide from "@components/pagePlantillas/plantillas/plantilla1/image/imgPortada.png"
import img1 from "@components/pagePlantillas/plantillas/plantilla1/image/img.png"
import slider1 from "@components/pagePlantillas/plantillas/plantilla1/image/slider1.png"
import slider2 from "@components/pagePlantillas/plantillas/plantilla1/image/slider2.png"
import slider3 from "@components/pagePlantillas/plantillas/plantilla1/image/slider3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      10 ejemplos de encuesta de satisfacción al cliente de un banco
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La satisfacción del cliente es un pilar fundamental para cualquier banco. Las encuestas son herramientas esenciales que permiten medir y entender las experiencias y expectativas de los usuarios.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Estos ejemplos incluyen preguntas variadas que abordan aspectos críticos como la calidad del servicio, la eficiencia de los procesos y la accesibilidad de las soluciones ofrecidas.
    <br /><br />
    Exploramos distintos formatos y tipos de preguntas para captar de forma efectiva la voz del cliente y mejorar continuamente los servicios bancarios.
  </p>
)

const data = {
  start: {
    support: "Plantilla | Banca y finanzas ",
    title: title,
    description: textDescription,
    classtext: "guide__description1",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Encuestas de experiencia general del cliente.",
      },
      {
        key: 2,
        text: "Encuestas de uso de la banca en línea.",
      },
      {
        key: 3,
        text: "Encuestas de productos financieros.",
      },
      {
        key: 4,
        text: "Encuestas de atención en agencia.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
